<template>
	<TheHeader hero="/images/screenshots/how-it-works-for-employees.png">
		<template v-slot:title>
			World-Class Digital ESOP Experience. <span class="line relative inline-block text-theme">Enjoy.</span>
		</template>
		<template v-slot:description>
			Optiomat offers paperless signature capabilities, keeps you informed and demonstrates the value of your option packages before each important decision.
		</template>
	</TheHeader>

	<section class="page-container md:flex md:items-start md:space-x-12 lg:pt-20 pt-10 md:space-y-0 space-y-16">
		<div class="xl:w-5/12 md:w-6/12">
			<WorkflowHead title="OptioTrack" icon="/images/workflows/optioplay-workflow.svg">
				Receive, accept and exercise share option plans in a few clicks. Track all of your transactions and payouts.
			</WorkflowHead>

			<div data-aos="fade-up">
				<TitleCheck title="Grants" class="mb-1">
					Get notified when a grant has been offered and is ready for your review. Sign digital documents right from your email inbox.
				</TitleCheck>
			</div>

			<div data-aos="fade-up">
				<TitleCheck title="Plans" class="mb-1">
					Access real-time data source to see how your ESOP moves from start to finish.
				</TitleCheck>
			</div>

			<div data-aos="fade-up">
				<TitleCheck title="Transactions" class="mb-1">
					Track every step in the ESOP process. Enjoy powerful notification functionality with automated alerts and reminders.
				</TitleCheck>
			</div>

			<div data-aos="fade-up">
				<TitleCheck title="Payouts" class="mb-1">
					See a detailed overview of your profits from company's economic success.
				</TitleCheck>
			</div>
		</div>
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 sticky top-24">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
				class=""
			>
				<GifTutorial gif="optiotrack.gif" class="md:m-0 mb-28"/>
			</kinesis-element>
		</kinesis-container>
	</section>

	<div class="page-container lg:mb-20">
		<div class="xl:w-5/12 md:w-6/12">
			<EmployeeInvitationOnly class="mb-10" />

			<a data-aos="fade-right" href="#" class="inline-block bg-theme lg:text-lg text-sm text-white font-extrabold lg:px-8 lg:py-4 px-4 py-3.5 lg:mr-5 mr-3 rounded-lg whitespace-nowrap shadow-lg btn-solid-hover">
				Learn More
			</a>
		</div>
	</div>
</template>
<script>

	import EmployeeInvitationOnly from "../components/EmployeeInvitationOnly";
	import VideoTutorial from "../components/VideoTutorial";
	import WorkflowHead from "../components/WorkflowHead";
	import TitleCheck from "../components/TitleCheck";
	import TheHeader from "../components/TheHeader";
	import GifTutorial from "../components/GifTutorial";

	export default {
		name: 'HowItWorksEmployees',
		components: {
			EmployeeInvitationOnly,
			VideoTutorial,
			WorkflowHead,
			GifTutorial,
			TitleCheck,
			TheHeader,
		}
	}
</script>